import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { getHrefs } from "../../utils/langService";

import imgClose from "../../images/common/close-dark.svg";

const CookieBar = ({ t, lang, close }) => {
  const { pages } = getHrefs(lang);

  return (
    <div className="CookieBar">
      <div className="container CookieBar__container">
        <p>
          {t.description} <Link to={`/${lang}/${pages[5]}/`}>{t.link}</Link>
        </p>

        <button onClick={close} className="CookieBar__close">
          <img src={imgClose} alt="close" />
        </button>
      </div>
    </div>
  );
};

CookieBar.propTypes = {
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default CookieBar;
