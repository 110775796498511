import React from "react";
import BlankLink from "./BlankLink";

import IconFacebook from "../../images/social-media/facebook.inline.svg";
import IconLinkedIn from "../../images/social-media/linkedin.inline.svg";
import IconTwitter from "../../images/social-media/twitter.inline.svg";
import IconInstagram from "../../images/social-media/instagram.inline.svg";
import IconBehance from "../../images/social-media/behance.inline.svg";
import IconDribbble from "../../images/social-media/dribbble.inline.svg";

const SocialMedia = () => (
  <div className="SocialMedia">
    <BlankLink href={process.env.FACEBOOK} className="link-flex SocialMedia__elem">
      <IconFacebook />
    </BlankLink>
    <BlankLink href={process.env.LINKEDIN} className="link-flex SocialMedia__elem">
      <IconLinkedIn />
    </BlankLink>
    <BlankLink href={process.env.TWITTER} className="link-flex SocialMedia__elem">
      <IconTwitter />
    </BlankLink>
    <BlankLink href={process.env.INSTAGRAM} className="link-flex SocialMedia__elem">
      <IconInstagram />
    </BlankLink>
    <BlankLink href={process.env.BEHANCE} className="link-flex SocialMedia__elem">
      <IconBehance />
    </BlankLink>
    <BlankLink href={process.env.DRIBBBLE} className="link-flex SocialMedia__elem">
      <IconDribbble />
    </BlankLink>
  </div>
);

export default SocialMedia;
