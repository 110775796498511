export const langsList = ["pl", "en"];

export const jobOffers = ["frontend-developer"];

export const getHrefs = locale => {
  const plHrefs = {
    pages: ["o-nas", "kariera", "blog", "kontakt", "wycena", "polityka-prywatnosci", "regulamin", "zamowienie", "prasa", "formularz-aplikacji"],
    services: {
      software: {
        name: "oprogramowanie",
        pages: [
          "strony-internetowe",
          "sklepy-internetowe",
          "blogi",
          "aplikacje-webowe",
          "aplikacje-desktopowe",
          "aplikacje-mobilne",
          "utrzymanie-monitoring-wsparcie"
        ]
      },
      cloud: { name: "chmura", pages: ["hosting", "serwery-vps", "poczta-email"] },
      marketing: { name: "marketing", pages: ["pozycjonowanie-stron-internetowych", "optymalizacja-seo", "obsluga-kampanii-reklamowych", "copywriting"] },
      additional: { name: "uslugi-dodatkowe", pages: ["tlumaczenia", "pomoc-prawna-it"] }
    },
    posts: ["lorem-ipsum"]
  };

  const enHrefs = {
    pages: ["about-us", "career", "blog", "contact", "estimate", "privacy-policy", "terms", "order", "press", "application-form"],
    services: {
      software: {
        name: "software",
        pages: ["websites", "webshops", "blogs", "web-applications", "desktop-applications", "mobile-applications", "maintenance-monitoring-support"]
      },
      cloud: { name: "cloud", pages: ["hosting", "vps-servers", "email"] },
      marketing: { name: "marketing", pages: ["website-positioning", "seo-optimisation", "handling-advertising-campaigns", "copywriting"] },
      additional: { name: "additional-services", pages: ["translations", "it-legal-assistance"] }
    },
    posts: ["lorem-ipsum"]
  };

  switch (locale) {
    case "en":
      return enHrefs;
    default:
      return plHrefs;
  }
};

const getHrefPosition = ({ list, part }) => {
  return list.indexOf(part);
};

export const changePath = ({ lang, pathname }) => {
  const splittedPath = pathname.split("/");
  const actualHrefsList = getHrefs(splittedPath[1]);
  const newHrefsList = getHrefs(lang);

  const pagePosition = getHrefPosition({ list: actualHrefsList.pages, part: splittedPath[2] });

  // services
  if (pagePosition === -1) {
    if (!splittedPath[2]) return `/${lang}/`;

    if (splittedPath[2] === actualHrefsList.services.software.name) {
      const softwarePosition = getHrefPosition({ list: actualHrefsList.services.software.pages, part: splittedPath[3] });
      return `/${lang}/${newHrefsList.services.software.name}/${newHrefsList.services.software.pages[softwarePosition]}/`;
    }

    if (splittedPath[2] === actualHrefsList.services.cloud.name) {
      const cloudPosition = getHrefPosition({ list: actualHrefsList.services.cloud.pages, part: splittedPath[3] });
      return `/${lang}/${newHrefsList.services.cloud.name}/${newHrefsList.services.cloud.pages[cloudPosition]}/`;
    }

    if (splittedPath[2] === actualHrefsList.services.marketing.name) {
      const marketingPosition = getHrefPosition({ list: actualHrefsList.services.marketing.pages, part: splittedPath[3] });
      return `/${lang}/${newHrefsList.services.marketing.name}/${newHrefsList.services.marketing.pages[marketingPosition]}/`;
    }

    if (splittedPath[2] === actualHrefsList.services.additional.name) {
      const additionalPosition = getHrefPosition({ list: actualHrefsList.services.additional.pages, part: splittedPath[3] });
      return `/${lang}/${newHrefsList.services.additional.name}/${newHrefsList.services.additional.pages[additionalPosition]}/`;
    }
  }

  // job offers
  if (pagePosition === 1 && splittedPath.length >= 3) {
    const jobOfferPosition = getHrefPosition({ list: jobOffers, part: splittedPath[3] });
    if (jobOfferPosition === -1) return `/${lang}/${newHrefsList.pages[1]}/`;
    return `/${lang}/${newHrefsList.pages[1]}/${splittedPath[3]}/`;
  }

  // posts
  if (pagePosition === 2 && splittedPath.length >= 3) {
    const postPosition = getHrefPosition({ list: actualHrefsList.posts, part: splittedPath[3] });
    if (postPosition === -1) return `/${lang}/${newHrefsList.pages[2]}/`;
    return `/${lang}/${newHrefsList.pages[2]}/${newHrefsList.posts[postPosition]}/`;
  }

  return `/${lang}/${newHrefsList.pages[pagePosition]}/`;
};
