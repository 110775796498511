import React from "react";
import PropTypes from "prop-types";

const BlankLink = ({ children, href, ...attributes }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...attributes}>
    {children}
  </a>
);

BlankLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  attributes: PropTypes.object
};

export default BlankLink;
